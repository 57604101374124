import React, { useEffect, useState } from 'react';
import { Link, useNavigate as useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getProfile, selectModificationProfileStatus, selectProfile, selectProfileStatus, updateLoginStatus, updateProfile } from '../reducerSlices/accountsSlice';
import { fetchStatus, redirectHubSpotMarketPlace } from '../../../api/client';
import { accountService } from '../services/accountService';
import { isFormValid } from '../../../component/Controls/services/formService';
// import { handlerError } from '../../../common/common';
import InputGroup from '../../../component/Controls/custom/inputGroup/inputGroup';
import ShortText from '../../../component/Controls/custom/textbox/shortText';
import PhoneText from '../../../component/Controls/custom/textbox/phoneText';
import EmailText from '../../../component/Controls/custom/textbox/emailText';
import PasswordText from '../../../component/Controls/custom/textbox/passwordText';
import { registerDetailsEN } from '../services/registerDetails';
import RegisterButton from '../../../component/Controls/custom/buttons/registerButton';
import DateText from '../../../component/Controls/custom/textbox/dateText';
import Selector from '../../../component/Controls/custom/selectors/selector';
import Logo from '../../../component/logo/logo';
import registerPageImg from '../../../assets/images/register-page.png';


const ProfileDetails = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const PROFILE_FORM = "ProfileForm";

    const profileStatus = useSelector(selectProfileStatus);
    const profile = useSelector(selectProfile);
    const modificationProfileStatus = useSelector(selectModificationProfileStatus);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [numberOfEmployees, setNumberOfEmployees] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [rfSubmitted, setRFSubmitted] = useState(false);

    const registerDetail = registerDetailsEN;

    const _loadProfile = async () => {
        await dispatch(getProfile({
        }));
    }

    useEffect(() => {
        if (profileStatus === fetchStatus.IDLE) {
            if (accountService.isAuthenticated()) {
                _loadProfile();
            }
        }
    }, [profileStatus]);

    useEffect(() => {
        if (profileStatus === fetchStatus.SUCCEEDED) {
            if (profile) {
                setFirstName(profile["FirstName"]);
                setLastName(profile["LastName"]);
                setPhoneNumber(profile["PhoneNumber"]);
                setEmail(profile["Email"]);
                // setDateOfBirth(profile["DateOfBirth"]);
                setCompanyName(profile["CompanyName"]);
                // setNumberOfEmployees(profile["NumberOfEmployees"]);
            }
        }
    }, [profileStatus]);


    const onProfileSaveClicked = (e) => {
        e.preventDefault();
        if (isFormValid(PROFILE_FORM)) {
            dispatch(updateProfile({
                firstName: firstName,
                lastName: lastName,
                phoneNumber: phoneNumber,
                // dateOfBirth: dateOfBirth,
                companyName: companyName,
                // numberOfEmployees: numberOfEmployees
            }));
        }
        else {
            setRFSubmitted(true);
        }
    }

    useEffect(() => {
        if (modificationProfileStatus === fetchStatus.SUCCEEDED) {
            if (accountService.isAuthenticated()) {
                redirectHubSpotMarketPlace();
            }
        }
    }, [modificationProfileStatus]);


    return (
        <>
            <div className="row justify-content-center align-items-center vh-100 m-0">
                <div className="col-4">
                    <img src={registerPageImg} className="login-page-image" />
                </div>
                <div className="col-6">
                    <div className="container new-card-ui py-3 p-5">
                        <div className="row">
                            <div className="col-md-12 p-3">
                                <Logo />
                                <br></br>
                                <br></br>
                                <h3 className="text-center">Quick Setup </h3>
                            </div>
                            <div className="col-md-12 p-3">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>{registerDetail.FirstName}</label>
                                            <InputGroup
                                                icon={<i className="fa fa-address-card"></i>}
                                                control={
                                                    <ShortText
                                                        id="txtFirstName"
                                                        name="txtFirstName"
                                                        ShortTextClasses="form-control"
                                                        placeholder={registerDetail.FirstName}
                                                        required={true}
                                                        errorMessage={registerDetail.FirstNameError}
                                                        form={PROFILE_FORM}
                                                        submited={rfSubmitted}
                                                        value={firstName}
                                                        onChange={(e) => setFirstName(e.target.value)} />
                                                } />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>{registerDetail.LastName}</label>
                                            <InputGroup
                                                icon={<i className="fa fa-address-card"></i>}
                                                control={
                                                    <ShortText
                                                        id="txtLastName"
                                                        name="txtLastName"
                                                        ShortTextClasses="form-control"
                                                        placeholder={registerDetail.LastName}
                                                        required={true}
                                                        errorMessage={registerDetail.LastNameError}
                                                        form={PROFILE_FORM}
                                                        submited={rfSubmitted}
                                                        value={lastName}
                                                        onChange={(e) => setLastName(e.target.value)} />
                                                } />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>{registerDetail.PhoneNumber}</label>
                                            <InputGroup
                                                icon={<i className="fa fa-phone"></i>}
                                                control={
                                                    <PhoneText
                                                        id="txtPhoneNumber"
                                                        name="txtPhoneNumber"
                                                        PhoneTextClasses="form-control"
                                                        placeholder={registerDetail.PhoneNumber}
                                                        required={true}
                                                        errorMessage={registerDetail.PhoneNumberError}
                                                        form={PROFILE_FORM}
                                                        submited={rfSubmitted}
                                                        value={phoneNumber}
                                                        onChange={(e) => setPhoneNumber(e.target.value)} />
                                                } />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>{registerDetail.Email}</label>
                                            <InputGroup
                                                icon={<i className="fa fa-envelope"></i>}
                                                control={
                                                    <EmailText
                                                        id="txtEmail"
                                                        name="txtEmail"
                                                        EmailTextClasses="form-control"
                                                        placeholder={registerDetail.Email}
                                                        required={true}
                                                        errorMessage={registerDetail.EmailError}
                                                        form={PROFILE_FORM}
                                                        submited={rfSubmitted}
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        disabled={true} />
                                                } />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <label>{registerDetail.BirthDate}</label>
                                            <InputGroup
                                                icon={<i className="fa fa-calendar"></i>}
                                                control={
                                                    <DateText
                                                        id="txtBirthDate"
                                                        name="txtBirthDate"
                                                        DateTextClasses="form-control"
                                                        required={true}
                                                        errorMessage={registerDetail.BirthDateError}
                                                        form={PROFILE_FORM}
                                                        submited={rfSubmitted}
                                                        value={dateOfBirth}
                                                        onChange={(e) => setDateOfBirth(e.target.value)}
                                                        max={(new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate())).toISOString().split('T')[0]}
                                                        min={(new Date(new Date().getFullYear() - 100, new Date().getMonth(), new Date().getDate())).toISOString().split('T')[0]} />
                                                } />
                                        </div>
                                    </div> */}
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>{registerDetail.CompanyName}</label>
                                            <InputGroup
                                                icon={<i className="fa fa-address-card"></i>}
                                                control={
                                                    <ShortText
                                                        id="txtCompanyName"
                                                        name="txtCompanyName"
                                                        ShortTextClasses="form-control"
                                                        placeholder={registerDetail.CompanyName}
                                                        required={true}
                                                        errorMessage={registerDetail.CompanyNameError}
                                                        form={PROFILE_FORM}
                                                        submited={rfSubmitted}
                                                        value={companyName}
                                                        onChange={(e) => setCompanyName(e.target.value)} />
                                                } />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>{registerDetail.NumberOfEmployees}</label>
                                            <InputGroup
                                                icon={<i className="fa fa-map-marker"></i>}
                                                control={
                                                    <Selector
                                                        id="drpNumberOfEmployees"
                                                        name="drpNumberOfEmployees"
                                                        SelectorClasses="form-select"
                                                        default={registerDetail.Default}
                                                        options={
                                                            <>
                                                                <option value="10">Under-10</option>
                                                                <option value="11-50">11-50</option>
                                                                <option value="51-200">51-200</option>
                                                                <option value="201-1000">201-1000</option>
                                                                <option value="Over-10000">Over-1000</option>
                                                            </>
                                                        }
                                                        required={true}
                                                        errorMessage={registerDetail.NumberOfEmployeesError}
                                                        form={PROFILE_FORM}
                                                        submited={rfSubmitted}
                                                        value={numberOfEmployees}
                                                        onChange={(e) => setNumberOfEmployees(e.target.value)} />
                                                } />

                                        </div>
                                    </div>
                                </div> */}
                                <div className="row pt-3">
                                    <div className="col-auto">
                                        <RegisterButton
                                            text="Save Details"
                                            title="Save Profile Details"
                                            disabled={profileStatus === fetchStatus.LOADING || modificationProfileStatus === fetchStatus.LOADING}
                                            loading={modificationProfileStatus === fetchStatus.LOADING}
                                            onRegisterClicked={(e) => onProfileSaveClicked(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default ProfileDetails; 